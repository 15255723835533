import React, {Component} from 'react';
import {connect} from 'react-redux';
import mapToObj from 'utils/mapToObj';
import PropTypes from 'prop-types';
import axios from 'axios';
import AppLoader from 'sharedComponents/AppLoader/AppLoader';
import SelectPriceForm from './components/SelectPriceForm/SelectPriceForm';

export class ChoiceSelectPrice extends Component {
  state = {
    choicePriceTypes: [],
    expireDate: null,
    loading: true,
  };

  componentDidMount() {
    this.loadChoicePriceTypes();
  }

  loadChoicePriceTypes = async () => {
    // TODO add managed price to choice price types
    const query = {
      query: `{
        choicePriceTypes( nameArray: ["Guaranteed Fixed", "Guaranteed Index", "Secure Fixed", "Managed Price", "Seasonal Price"] ) {
          id
          name
          specificLegal
        }
        choiceParam(
          region: "ALL",
          billClass: "ALL",
          paramType: "expire_date"
        ) {
          effectiveDate
        }
      }`,
    };

    let res;
    try {
      res = await axios.post('/graphql/', query);
    } catch (err) {
      // TODO: airbrake and support email message
      return err;
    }
    const {choicePriceTypes, choiceParam} = res.data.data;
    return this.setState({
      choicePriceTypes,
      expireDate: choiceParam.effectiveDate,
      loading: false,
    });
  };

  render() {
    return (
      <>
        <AppLoader loading={this.state.loading} />
        {!this.state.loading && (
          <SelectPriceForm
            formType={this.props.formType}
            quote={this.props.quote}
            choicePriceTypes={this.state.choicePriceTypes}
            complete={this.props.complete}
            callBack={this.props.callBack}
            openControlNumberForm={this.props.openControlNumberForm}
            expireDate={this.state.expireDate}
          />
        )}
      </>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    formState: state.form,
  };
}

export default connect(mapStateToProps)(ChoiceSelectPrice);

ChoiceSelectPrice.propTypes = {
  formType: PropTypes.string,
  complete: PropTypes.bool,
  openControlNumberForm: PropTypes.bool,
  callBack: PropTypes.func.isRequired,
  quote: PropTypes.shape({
    id: PropTypes.number,
    choiceQuoteNotes: PropTypes.arrayOf(PropTypes.shape({})),
    premises: PropTypes.arrayOf(PropTypes.number),
    quoteData: PropTypes.shape({
      expirationDate: PropTypes.string,
      paramType: PropTypes.string,
      selectedPrice: PropTypes.string,
      accountList: PropTypes.arrayOf(PropTypes.shape({})),
      fmb1Total: PropTypes.number,
      fmb2Total: PropTypes.number,
      fmb3Total: PropTypes.number,
      gFixed2: PropTypes.number,
      gFixed3: PropTypes.number,
      gFixed1: PropTypes.number,
      baseTpFuelIndex: PropTypes.number,
    }),
  }),
};

ChoiceSelectPrice.defaultProps = {
  formType: 'ResCom',
  complete: false,
  openControlNumberForm: false,
  quote: {
    choiceQuoteNotes: [],
    quoteData: {
      accountList: [],
    },
  },
};
