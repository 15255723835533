import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import {
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table
} from '@material-ui/core';

import graphqlCall from 'utils/graphqlCall';
import graphqlObj from 'utils/graphqlObj';
import { FORM_ERROR } from 'final-form';

import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import InputFieldArray from 'sharedComponents/FinalForm/InputFieldArray/InputFieldArray';
import Submit from 'sharedComponents/FinalForm/Submit/Submit';

export const ChoicePriceTypes = () => {

  const [priceTypes, setPriceTypes] = useState([])

  const loadChoicePriceTypes = async () => {
    const query = {
      query: `{
        choicePriceTypes( nameArray: ["Guaranteed Fixed", "Guaranteed Index", "Secure Fixed", "Managed Price", "Seasonal Price"] ) {
          id
          name
          specificLegal
        }
      }`,
    };

    let res;
    try {
      res = await axios.post('/graphql/', query);
    } catch (err) {
      return;
    }

    const { choicePriceTypes } = res.data.data;
    return choicePriceTypes;
  };

  useEffect(() => {
    (async () => {
      const priceTypes = await loadChoicePriceTypes();
      return setPriceTypes(priceTypes);
    })();
  }, []);

  const onSubmit = async (values) => {
    const choicePriceTypes = graphqlObj(values.choicePriceTypes)

    const queryString = {
      query: `mutation {
          updateChoicePriceTypes(
            choicePriceTypes: ${choicePriceTypes},
          ) {
            id
            name
            specificLegal
          }
        }`,
    };
    try {
      await graphqlCall(queryString);
    } catch (err) {
      return { [FORM_ERROR]: err.toString() };
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators }}
      initialValues={{ choicePriceTypes: priceTypes }}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell size="small" align="center">Name</TableCell>
                <TableCell align="center">Specific Legal</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <FieldArray name="choicePriceTypes">
                {({ fields }) => fields.map((name, i) => (
                  <TableRow key={i}>
                    <TableCell size="small" align="center">
                      <h2>{fields.value[i].name}</h2>
                    </TableCell>
                    <TableCell align="center">
                      <InputFieldArray
                        name={name}
                        type="text"
                        objKey="specificLegal"
                        multiline
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </FieldArray>
            </TableBody>
          </Table>
          <Submit submitting={submitting}>
            Update
          </Submit>
        </form>
      )}
    </Form>
  );
};


export default ChoicePriceTypes;

// ChoicePriceTypes.propTypes = {};

// ChoicePriceTypes.defaultProps = {};
